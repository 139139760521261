<template>
  <vineyards-module-component />
</template>

<script>
import VineyardsModuleComponent from "@/components/admin/modules/VineyardsModuleComponent";

export default {
  name: "VineyardsModule",
  title: "Gestión de Viñedos | Baja California Health Tourism",
  components: { VineyardsModuleComponent },
};
</script>

<style scoped></style>
