<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
            mdi-key
          </v-icon>
        </template>

        <span>Keywords</span>

        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" max-width="500px" persistent>
            <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-title class="text-h6 grey lighten-2">
                        <span class="headline">Keywords</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="keywords" label="Keywords" required
                                        :rules="rules"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="close">
                            Cancelar
                        </v-btn>
                        <v-btn :disabled="!valid || saveChanges" color="primary" :loading="saveChanges"
                            @click="saveKeywords">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-tooltip>
</template>

<script>
import axios from "axios";
export default {
    name: "Keywords",
    props: ["entity", "url"],
    data(){
        return {
            dialog: false,
            keywords: "",
            saveChanges: false,
            rules: [
                (v) => !!v || 'El campo es requerido'
            ],
            valid: false,
        }
    },

    mounted(){
        this.keywords = this.entity.keywords;
    },

    methods: {
        openDialog(){
            this.keywords = this.entity.keywords;
            this.dialog = true;
            this.valid = false;
        },

        close(){
            this.keywords = "";
            this.dialog = false;
            this.valid = false;
        },

        saveKeywords(){
            this.saveChanges = true;
            axios.post(this.url, {
                                id: this.entity.id,
                                keywords: this.keywords
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'X-Requested-With': 'XMLHttpRequest',
                                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                                }
                            }
                        ).then(resp=>{
                            if(resp.status==200){
                                this.dialog = false;
                                this.$emit('reload');
                            }
                        }).catch(err=>console.log(err)).finally(()=>{
                            this.saveChanges = false;
                        })
        }
    }
}
</script>